var Handlebars = require('C:/Users/arngaj/source/repos/Wordpress/ssc/rymdkollen/rymdkollen-2019-frontend/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " mobile-menu--with-search";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "		<form action=\""
    + alias4(((helper = (helper = helpers.searchPage || (depth0 != null ? depth0.searchPage : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchPage","hash":{},"data":data}) : helper)))
    + "\" method=\""
    + alias4(((helper = (helper = helpers.searchMethod || (depth0 != null ? depth0.searchMethod : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchMethod","hash":{},"data":data}) : helper)))
    + "\" class=\"mobile-menu__search-form\">\r\n			<input type=\"search\" name=\""
    + alias4(((helper = (helper = helpers.searchParameter || (depth0 != null ? depth0.searchParameter : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchParameter","hash":{},"data":data}) : helper)))
    + "\" autocomplete=\"off\" placeholder=\""
    + alias4(((helper = (helper = helpers.searchPlaceholder || (depth0 != null ? depth0.searchPlaceholder : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchPlaceholder","hash":{},"data":data}) : helper)))
    + "\" />\r\n			<input type=\"submit\" value=\""
    + alias4(((helper = (helper = helpers.searchPlaceholder || (depth0 != null ? depth0.searchPlaceholder : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchPlaceholder","hash":{},"data":data}) : helper)))
    + "\" class=\"usability--visuallyhidden\" />\r\n		</form>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<nav id=\"mobile-menu\" class=\"mobile-menu"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchField : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" aria-hidden=\"true\" aria-label=\"mobilmenu\">\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.searchField : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	<div role=\"menu\" id=\"mobile-menu-paneholder\"></div>\r\n</nav>\r\n<div id=\"mobile-menu-page-cover\" class=\"mobile-menu-page-cover\"></div>\r\n";
},"useData":true});