/**
 * Tab usability
 * This plugin adds a class to elements when they have tabfocus, which allows styling without the style applying to the :active state.
 * @version 3.0.0
 * @exports TabUsability
 */
export default class TabUsability {
	/**
	 * Constructor
	 * @public
	 */
	constructor() {
		this._initEvents();
	}

	/**
	 * Initiates events.
	 * @private
	 */
	_initEvents() {
		document.addEventListener('keyup', event => {
			let el = event.target,
				keyCode = event.keyCode ? event.keyCode : event.which;

			if (
				['a', 'button', 'input', 'textarea', 'select'].indexOf(
					el.tagName.toLowerCase()
				) !== -1 &&
				keyCode === 9
			) {
				el.classList.add('usability--tab-focus');
			}
		});

		document.addEventListener('click', event => {
			let el = event.target;

			if (['input', 'textarea'].indexOf(el.tagName.toLowerCase()) !== -1) {
				el.classList.add('usability--tab-focus');
			}
		});

		document.addEventListener(
			'blur',
			event => {
				let el = event.target;

				if (el.classList && el.classList.contains('usability--tab-focus')) {
					el.classList.remove('usability--tab-focus');
				}
			},
			true
		);
	}
}
