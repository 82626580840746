import MobileMenu from './components/mobileMenu/mobileMenu';
import TabUsability from './components/tabUsability/tabUsability';
import ObjectFitImages from 'object-fit-images';

/**
 * App
 * This starting point for this application.
 * @version 3.0.0
 * @exports App
 */
export default class App {
	constructor() {
		// Init mobile menu
		new MobileMenu(document.querySelector('[data-type="mobileMenu"]'), {
			ajaxPath: mainConfig.menuAjaxPath,
			ajaxActivePane: mainConfig.menuAjaxActivePane,
			searchField: false,
			searchMethod: mainConfig.searchMethod,
			searchPage: mainConfig.searchPage,
			searchParameter: mainConfig.searchParameter,
			searchPlaceholder: mainConfig.searchPlaceholder,
		});

		// Init tabUsability
		new TabUsability();

		ObjectFitImages();
	}
}
