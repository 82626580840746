import { extend, stripTags } from '../utilities/utilities';
import menuTemplate from './mobileMenu.template.hbs';
import paneTemplate from './mobileMenu.pane.template.hbs';

/**
 * Mobile menu
 * Javascript functionality for a (mobile) menu.
 * @version 3.0.0
 * @exports MobileMenu
 */
export default class MobileMenu {
	/**
	 * Constructor
	 * @param {Object} el - The page main menu as a DOM node.
	 * @param {Object} options - Contains options for the menu.
	 * @public
	 */
	constructor(el, options) {
		/** Boolean indicating if the search-field should be rendered. */
		this.searchField = false;

		/** String with prefered search method. */
		this.searchMethod = 'GET';

		/** String with url to search page. */
		this.searchPage = '/search';

		/** String with the parameter name for searching. */
		this.searchParameter = 'query';

		/** String with the placeholder text for the search-field. */
		this.searchPlaceholder = 'Search';

		/** Object which will contain all panes in the menu. */
		this.menuPages = {};

		/** Placeholder for the pane holder. */
		this.paneHolder = null;

		/** Placeholder for the initial active menu pane. */
		this.activePane = null;

		/** Placeholder for the ajax path (if the menu should run with ajax). */
		this.ajaxPath = null;

		/** String with the id of the active pane (used for the initial ajax request). */
		this.ajaxActivePane = 'root';

		/** String with the active-class for page menu items. */
		this.activeClass = 'is-active';

		/** The page-container DOM element. */
		this.pageContainer = document.getElementById('page-container');

		/** Placeholder for menu DOM element. */
		this.menuNode = null;

		/** Placeholder for the menu cover DOM element (covers the page when the menu is opened). */
		this.menuCoverNode = null;

		/** The menu button DOM element. */
		this.menuButtonNode = document.getElementById('menu-button');

		this.allBackgroundElements = document.querySelectorAll(
			"a[href]:not([tabindex='-1']):not([id='menu-button']),area[href]:not([tabindex='-1']),input:not([disabled]):not([tabindex='-1']),select:not([disabled]):not([tabindex='-1']),textarea:not([disabled]):not([tabindex='-1']),button:not([disabled]):not([tabindex='-1']),iframe:not([tabindex='-1']),[tabindex]:not([tabindex='-1']),[contentEditable=true]:not([tabindex='-1'])"
		);

		extend(this, options);
		this.el = el;

		if (this.menuButtonNode) {
			this._init();
		}
	}

	/**
	 * The initialization function for this module.
	 * @private
	 */
	_init() {
		this._renderMenu();

		this.menuButtonNode.setAttribute('href', '#mobile-menu');

		if (
			this.ajaxPath === undefined ||
			this.ajaxPath === false ||
			this.ajaxPath === null ||
			this.ajaxPath === ''
		) {
			try {
				this._parseMenu(this.el);
			} catch (e) {}
		} else {
			this._initAjax();
		}

		this._initEvents();
	}

	/**
	 * Opens the menu.
	 * @public
	 */
	openMenu() {
		if (this.addVisibleClassInterval) {
			clearTimeout(this.addVisibleClassInterval);
		}
		this.pageContainer.classList.add('mobile-menu-slideout');
		this.menuNode.setAttribute('aria-hidden', false);
		this.menuButtonNode.setAttribute('aria-expanded', true);
		document.getElementsByTagName('BODY')[0].classList.add('nav-open');
		document.getElementsByTagName('BODY')[0].classList.add('nav-is-visible');

		// Set focus on the first link inside mobile menu pane.
		const firstLink = document.querySelector(
			'.mobile-menu__pane--active .nav-primary__menu__item__link'
		);
		firstLink.focus();

		// Trap focus inside mobile menu pane.
		this.allBackgroundElements.forEach(element => {
			element.setAttribute('tabindex', '-1');
		});

		this.addClassInterval = setTimeout(() => {
			document.getElementsByTagName('BODY')[0].classList.add('nav-opened');
		}, 100);
	}

	/**
	 * Closes the menu.
	 * @public
	 */
	closeMenu() {
		if (this.addClassInterval) {
			clearTimeout(this.addClassInterval);
		}
		this.menuNode.setAttribute('aria-hidden', true);
		this.menuButtonNode.setAttribute('aria-expanded', false);
		document.getElementsByTagName('BODY')[0].classList.remove('nav-open');
		document.getElementsByTagName('BODY')[0].classList.remove('nav-opened');

		this.menuButtonNode.focus();

		// Restore focus when closing the mobile menu pane.
		this.allBackgroundElements.forEach(element => {
			element.setAttribute('tabindex', '0');
		});

		this.addVisibleClassInterval = setTimeout(() => {
			document
				.getElementsByTagName('BODY')[0]
				.classList.remove('nav-is-visible');
			this.pageContainer.classList.remove('mobile-menu-slideout');
		}, 400);
	}

	/**
	 * Toggles the menu.
	 * @public
	 */
	toggleMenu() {
		this.menuNode.getAttribute('aria-hidden') === 'true'
			? this.openMenu()
			: this.closeMenu();
	}

	/**
	 * Renders the mobile menu html.
	 * @private
	 */
	_renderMenu() {
		let menuHtml = menuTemplate({
			searchField: this.searchField,
			searchMethod: this.searchMethod,
			searchPage: this.searchPage,
			searchParameter: this.searchParameter,
			searchPlaceholder: this.searchPlaceholder,
		});

		document
			.getElementsByTagName('BODY')[0]
			.insertAdjacentHTML('BeforeEnd', menuHtml);
		this.menuNode = document.getElementById('mobile-menu');
		this.menuCoverNode = document.getElementById('mobile-menu-page-cover');
		this.paneHolder = document.getElementById('mobile-menu-paneholder');
	}

	/**
	 * Renders the html for a pane.
	 * @private
	 */
	_renderMenuPane(pane, place) {
		let paneHtml = paneTemplate(pane);
		this.paneHolder.insertAdjacentHTML(place || 'BeforeEnd', paneHtml);
	}

	/**
	 * Parses the page menu and create mobile menu panes.
	 * @param {Object} el - The page main menu as a DOM node.
	 * @private
	 */
	_parseMenu(el) {
		let parser = new DOMParser(),
			html = stripTags(el.innerHTML, '<ul><li><a>'),
			doc = parser.parseFromString(html, 'text/html'),
			jsonData = { items: this._parsePageMenu(doc.querySelector('ul')) };

		this._generateMenuPages(jsonData.items, 'root');

		for (let key in this.menuPages) {
			if (this.menuPages.hasOwnProperty(key)) {
				this._renderMenuPane(this.menuPages[key]);
			}
		}

		if (this.activePane === null || this.activePane === 'root') {
			this.activePane = 'root';
		}
		this._openMenuPane(
			document.getElementById('mobile-menu-pane-' + this.activePane)
		);
	}

	/**
	 * Recursivly parses the page menu and collect all ul/li/a.
	 * @param {Object} el - A DOM node to parse.
	 * @param {Object} parentObj - The parent DOM node.
	 * @private
	 */
	_parsePageMenu(obj, parentObj) {
		let json = [];

		if (obj.hasChildNodes()) {
			let child = obj.firstChild;

			while (child) {
				if (child.nodeType === 1) {
					let tag = {
						node: child.tagName,
						classlist: child.getAttribute('class') || '',
					};
					if (child.tagName === 'A') {
						tag.text = child.textContent || child.innerText;
						tag.url = child.getAttribute('href');
						parentObj.link = tag;
					} else {
						if (child.tagName === 'UL') {
							tag.ul = true;
						}
						tag.items = this._parsePageMenu(child, tag);
						json.push(tag);
					}
				}
				child = child.nextSibling;
			}
		}
		return json;
	}

	/**
	 * Generate panes for each submenu (recursive).
	 * @param {Object[]} arr - Array of items.
	 * @param {Object} itemParent - The parent item.
	 * @param {Object} paneParent - The parent pane item.
	 * @private
	 */
	_generateMenuPages(
		arr,
		itemParent,
		paneParent,
		parentItemName,
		parentItemUrl
	) {
		let parentName = '',
			parentUrl = null;

		arr.forEach((item, i) => {
			if (item.node === 'LI') {
				item.parent = itemParent;
				item.id = itemParent + '-' + i;
				if (this.menuPages[itemParent] === undefined) {
					this.menuPages[itemParent] = {
						id: itemParent,
						parent: paneParent || null,
						parentName: parentItemName || '',
						parentUrl: parentItemUrl || null,
						items: [],
					};
				}
				this.menuPages[itemParent].items.push(item);
				if (item.link !== undefined) {
					parentName = item.link.text;
					parentUrl = item.link.url;
					if (item.link.classlist.indexOf(this.activeClass) !== -1) {
						this.activePane = itemParent;
					}
				}
			} else {
				item.id = itemParent;
				itemParent = paneParent;
				parentName = parentItemName;
				parentUrl = parentItemUrl;
			}

			if (item.items.length > 0) {
				this._generateMenuPages(
					item.items,
					item.id,
					itemParent,
					parentName,
					parentUrl
				);
			}
		});
	}

	/**
	 * Opens a submenu (pane).
	 * @param {Object} pane - The pane to be opened as a DOM node.
	 * @private
	 */
	_openMenuPane(pane) {
		let activeMenuLinks = this.menuNode.querySelectorAll(
				'[aria-expanded="true"]'
			),
			activePane = this.menuNode.querySelector('.mobile-menu__pane--active'),
			targets = document.querySelectorAll('[data-target="' + pane.id + '"]'),
			ancestor;

		if (activePane !== null) {
			activePane.setAttribute('aria-hidden', true);
		}
		if (activeMenuLinks.length > 0) {
			activeMenuLinks.forEach(activeMenuLink => {
				activeMenuLink.setAttribute('aria-expanded', false);
			});
		}
		pane.setAttribute('aria-hidden', false);
		targets.forEach(link => {
			link.setAttribute('aria-expanded', true);
		});

		ancestor = document.getElementById(
			'mobile-menu-pane-' + pane.getAttribute('data-parent')
		);
		while (ancestor !== null) {
			ancestor.classList.add('mobile-menu__pane--active-trail');
			ancestor = document.getElementById(
				'mobile-menu-pane-' + ancestor.getAttribute('data-parent')
			);
		}

		if (activePane !== null) {
			activePane.classList.remove('mobile-menu__pane--active');
		}
		pane.classList.remove('mobile-menu__pane--active-trail');
		pane.classList.add('mobile-menu__pane--active');
	}

	/**
	 * Initiate ajax.
	 * @private
	 */
	_initAjax() {
		require(['axios'], axios => {
			this.axios = axios;
			this._loadMenu(this.ajaxActivePane);
		});
	}

	/**
	 * Loads a menuPane through ajax.
	 * @private
	 */
	_loadMenu(id) {
		let requestUrl =
			this.ajaxPath.indexOf('?') === -1
				? this.ajaxPath + '?id=' + id
				: this.ajaxPath + '&id=' + id;

		this.axios({
			url: requestUrl,
			method: 'get',
			headers: {
				'x-requested-with': 'XMLHttpRequest',
			},
		})
			.then(response => {
				let loadingLink = this.menuNode.querySelector('.is-loading'),
					activePane;

				if (response.status === 200) {
					let data = response.data;
					if (data.d !== undefined) {
						data = data.d;
					}

					// This is needed to get ajax back links to animate correctly.
					activePane = this.menuNode.querySelector(
						'.mobile-menu__pane--active'
					);
					if (
						activePane !== null &&
						(activePane.getAttribute('data-parent') === data.id ||
							parseInt(activePane.getAttribute('data-parent'), 10) === data.id)
					) {
						data.classlist = 'mobile-menu__pane--active-trail';
						this._renderMenuPane(data, 'AfterBegin');
					} else {
						this._renderMenuPane(data);
					}

					setTimeout(() => {
						this._openMenuPane(
							document.getElementById('mobile-menu-pane-' + data.id)
						);
					}, 10);
				} else {
					alert('An error occured, try again later.');
				}

				if (loadingLink !== null) {
					loadingLink.classList.remove('is-loading');
				}
			})
			.catch(() => {});
	}

	/**
	 * Initiate events.
	 * @private
	 */
	_initEvents() {
		this.menuButtonNode.addEventListener('click', event => {
			event.preventDefault();
			this.toggleMenu();
		});

		this.menuCoverNode.addEventListener('click', event => {
			event.preventDefault();
			this.closeMenu();
		});

		document.addEventListener('click', event => {
			let link = event.target;

			// Normal navigation
			if (link.classList.contains('mobile-menu-nav')) {
				event.preventDefault();

				if (
					document.getElementById(link.getAttribute('aria-controls')) !== null
				) {
					this._openMenuPane(
						document.getElementById(link.getAttribute('aria-controls'))
					);
				} else if (this.ajaxPath) {
					link.classList.add('is-loading');
					this._loadMenu(link.getAttribute('data-target'));
				} else {
					alert('An error occured, try again later.');
				}
			}
		});

		// Handle anchor links in the menu.
		const menuItems = document.querySelectorAll(
			'.nav-primary__menu__item__link'
		);
		menuItems.forEach(el => {
			el.addEventListener('click', event => {
				const linkUrl = event.target.href;

				if (linkUrl) {
					const anchorPosition = linkUrl.indexOf('#');

					if (anchorPosition !== -1) {
						event.preventDefault();
						this.closeMenu();

						setTimeout(() => {
							const hash = linkUrl.substring(anchorPosition);
							const target = document.querySelector(hash);

							target.scrollIntoView({
								behavior: 'smooth',
								block: 'start',
							});

							history.pushState(null, null, hash);
						}, 500);
					}
				}
			});
		});
	}
}
